import Testing from '../components/Testing'

function MediaPage() {
  return (
    <div className="px-5 pb-5">
      <Testing />
    </div>
  );
}

export default MediaPage;